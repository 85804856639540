<template>
  <div class="container">
    <div class="username">
      <img
        src="http://dspcloud.spay365.com/066f5c46b18bb700ce3b243149719b3a161010a5.png"
        alt=""
      />
      <input
        type="tel"
        placeholder="请输入您的手机号码"
        v-model="mobile"
        maxlength="11"
      />
    </div>
    <div class="passward">
      <img
        src="http://dspcloud.spay365.com/0d3635a5e02458047add8d9475d740598766b1bf.png"
        alt=""
        class="icon"
      />
      <input type="tel" placeholder="请输入短信验证码" v-model="code" maxlength="4" />
      <button @click="getcode">{{ get }}</button>
      <!-- <img
        src="@//assets/img/game/lockGet.png"
        alt=""
        @click="getcode"
        class="img"
      /> -->
    </div>
    <div class="login" @click="login"></div>
  </div>
</template>
<style scoped>
.container {
  width: 7.5rem;
  min-height: 100%;
  background: url('~@/assets/img/apponintBg.png');
  background-size: 100% 100%;
  margin: 0 auto;
  position: fixed;
}
.username {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-left: 0.9rem;
  width: 5.56rem;
  height: 1.1rem;
  font-size: 0.3rem;
  color: #c5c4c3;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
}
.username img {
  width: 0.45rem;
  height: 0.55rem;
  margin-right: 0.3rem;
}
.username input {
  width: 3.9rem;
  height: 0.4rem;
  outline: none;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}
.passward {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.9rem;
  width: 5.56rem;
  font-size: 0.3rem;
  color: #c5c4c3;
  height: 1.1rem;
  margin-top: 0.5rem;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
}
.icon {
  width: 0.45rem;
  height: 0.55rem;
  /* margin-right: 0.3rem; */
}
.passward input {
  width: 2.4rem;
  height: 0.4rem;
  margin: 0 0.2rem 0 0.3rem;
  outline: none;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}
/* .img {
  width: 1.4rem;
  height: 0.8rem;
} */
.login {
  width: 5.05rem;
  height: 0.85rem;
  margin-top: 1.15rem;
  margin-left: 1.2rem;
  background: url('~@/assets/img/gameLogin.png') no-repeat;
  background-size: 100% 100%;
}
button {
  width: 1.4rem;
  height: 0.48rem;
  background: #facc42;
  border-radius: 0.5rem;
  color: #b05103;
}
</style>
<script>
export default {
  name: 'login',
  data() {
    return {
      mobile: '',
      code: '',
      source: '',
      get: '获取',
      totalTime: '60',
      wrap: '',
      appid: '',
      path:this.$route.query.path
    }
  },

  components: {},
  created() {},
  mounted() {
    this.getauthorization('source')
  },
  beforeMount() {},
  methods: {
    getcode() {
      if (this.mobile == '') {
        this.$toast({
          message: '请输入手机号码',
        })
      } else {
        this.request.post('/api/n/send/login/sms', { mobile: this.mobile }).then(
          (res) => {
            if (res.code == 0) {
              var timer = setInterval(() => {
                if (this.totalTime == 0) {
                  clearTimeout(timer)
                  this.get = '获取'
                } else {
                  this.get = '还剩' + this.totalTime + '秒'
                  this.totalTime--
                }
              }, 1000)
              this.$toast({
                message: '验证码发送中，请稍后...',
              })
            }
          }
        )
      }
    },
    login() {
      if (this.code == '') {
        this.$toast({
          message: '请输入验证码',
        })
      } else {
        this.request.post('/api/n/user/sms/login', {
          mobile: this.mobile,
          code: this.code,
          source: this.source
        }).then((res) => {
          if (res == 'ok') {
            this.$router.push({path: '/h/scm/mall/hotSale', query: this.$route.query});

            //
            // if(this.wrap==''){
            //    if(this.appid!=''){
            //     this.$router.push({ name: 'gamedetail', query: {appid:this.appid } })
            //    }else{
            //     this.$router.push({ name: 'index', query: { } })
            //    }
            // }else{
            //    this.$router.push({ name: 'gamedetail', query: { wrap: '0',appid:this.appid } })
            // }
          } else {
            console.log(res)
            this.$toast({
              message: '登录失败',
            })
          }
        })
      }
    },
    getauthorization(source) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == source) {
          this.source = pair[1]
          // return [pair[1]
        }
      }

      if (!this.source) {
        this.source = 3
      }

      return undefined;
    },
  },
}
</script>
